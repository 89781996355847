import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { css } from '@emotion/react'
import Seo from '~components/Seo'
import RenderModule from '~components/RenderModule'
import Image from '~components/Image'
import Section from '~components/Section'
import RichText from '~components/RichText'
import DataViewer from '~utils/DataViewer'
import resolvelink from '~utils/resolveLink'
import styled from '@emotion/styled'
import { mobile } from '~styles/global'
import useBreakpoint from '~utils/useBreakpoint'
import resolveLink from '~utils/resolveLink'
import useWindow from '~utils/useWindow'
import { motion, useAnimation, AnimateSharedLayout } from 'framer-motion'
import AnimatedTabButton from '~components/AnimatedTabButton'


const About = ({ data, pageContext }) => {

	const window = useWindow()
	const [currentTab, setCurrentTab] = useState()
	const controls = useAnimation()
	const { isTablet } = useBreakpoint()

	const isCurrent = page => 
		resolveLink(page).replace(/\/$/, '') === window?.location.pathname.replace(/\/$/, '')

	const toPage = (page, i) => {
		if(isCurrent(page)) return null
		setCurrentTab(i)
		controls.start('hidden')
		window.setTimeout(() => navigate(resolveLink(page), { state: { disableScrollUpdate: true }}), 600)
	}

	const findCurrentIndex = () => {
		return data.tabs.nodes.findIndex(tab => {
			return tab.slug?.current === pageContext.slug
		})
	}

	useEffect(() => {
		controls.start('visible')
		setCurrentTab(findCurrentIndex())
	}, [])

	const { isMobile } = useBreakpoint()

	const { title, content } = data.page
	return(
		<>
			<Seo 
				title={title}
				metaTitle={content?.seo?.metaTitle}
				description={content?.seo?.metaDescription}
				image={content?.seo?.socialImage}
			/>
			<DataViewer data={data} name="data"/>
			<div key={pageContext?.slug + 'image'}>
				<motion.div 
					animate={controls}
					transition={{ duration: 0.6 }}
					variants={{
						visible: { opacity: 1 },
						hidden: { opacity: 0 }
					}}
				>
					<Image 
						image={content.headerImage} 
						aspectRatio={isMobile ? 1 : 2.62}
					/>
				</motion.div>
			</div>
			<Tabs>
				<AnimateSharedLayout>
					{data.tabs?.nodes?.map((tab, i) => (
						<AnimatedTabButton 
							key={tab.slug} 
							onClick={() => toPage(tab, i)}
							isSelected={currentTab === i}
						>
							<h3 className={!isTablet ? 'h1' : ''}>{tab.content?.tabTitle}</h3>
						</AnimatedTabButton>
					))}
				</AnimateSharedLayout>
			</Tabs>
			<div key={pageContext?.slug}>
				<motion.div 
					animate={controls}
					initial='hidden'
					transition={{ duration: 0.6 }}
					variants={{
						visible: { opacity: 1 },
						hidden: { opacity: 0 }
					}}
				>
					<Headline>
						<Introduction>
							<RichText content={content.introduction} />
						</Introduction>
					</Headline>
					{content.flexibleContent?.map(module => (
						<RenderModule 
							key={module._key} 
							module={module}
						/>
					))}
				</motion.div>
			</div>
		</>
	)
}

const Tabs = styled.header`
	display: flex;
	margin: var(--l) 40px 0px;
	border-bottom: 1px solid #C6C3B8;
	${mobile}{
		justify-content: space-around;
		margin: 0 20px;
	}
	@media (max-width: 500px){
		margin: 0px;
	}
`

const Headline = styled(Section)`
	border-bottom: 1px solid var(--black);
`
const Introduction = styled.h1`
	text-align: center;
	padding: var(--xxxl) 5%;
	grid-column: span 12;
	max-width: 870px;
	margin: 0 auto;
	${mobile}{
		grid-column: span 6;
	}
`
About.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
}

export const query = graphql`
  query AboutQuery($slug: String) {
    page: sanityAboutPage(slug: {current: {eq: $slug}}) {
      title
			content{
				seo{
					...seo
				}
				headerImage{
					...imageWithAlt
				}
				introduction: _rawIntroduction
				tabTitle
				flexibleContent{
					... on SanityArticleRow {
						...articleRow
					}
					... on SanityCarousel {
						...carousel
					}
					... on SanityNumberedGrid {
						...numberedGrid
					}
					... on SanityTextImage {
						...textImage
					}
					... on SanityTextImages {
						...textImages
					}
					... on SanityImageSection {
						...imageSection
					}
					... on SanityFeaturedArticle {
						...featuredArticle
					}
					... on SanityReviews {
						...reviews
					}
					... on SanityProductRow {
						...productRow
					}
					... on SanityProductCatalogue {
						...productCatalogue
					}
				}
			}
    }
		tabs: allSanityAboutPage(sort: {order: ASC, fields: _createdAt}) {
			nodes {
				_type
				slug {
					current
				}
				content {
					tabTitle
				}
			}
		}
  }
`

export default About